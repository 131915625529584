<template>
    <div>
        <el-form :inline="true">
            <el-form-item>
                <el-input v-model.trim="searchObj.platformName" placeholder="请输入平台名称" class="centerInput"></el-input>
            </el-form-item>
            <el-form-item>
                <button class="centerCzbtn" type="button" @click="getTableData(searchPage)">搜索</button>
            </el-form-item>
            <el-form-item>
                <button class="centerBtn resetbtn" type="button" @click="reset">重置</button>
            </el-form-item>
        </el-form>
        <Table :data1="tableData" :columns1="columns" :total="total" @getAjax="getTableData" :loading="loading" :page="searchObj.page" :size="searchObj.size"/>

    </div>
</template>

<script>
    import Table from '@/components/Table'

    import {
        _FindAllPage
    } from '@/api/referenceSystem'
    export default {
        components: {
            Table
        },
        data() {
            return {
                loading: false,
                searchObj: {
                    platformName: "",
                    page: 1,
                    size: 10
                },
                searchPage:{
                    limit: 10,
                    page: 1
                },
                total:"",
                tableData: [],
                columns: [{
                        label: "平台名称",
                        prop: "platformName",
                    },
                    {
                        label: "平台属性",
                        prop: "platformProperties",
                    },
                    {
                        label: "创建时间",
                        prop: "createTime",
                    },
                    {
                        label: "到期时间",
                        prop: "expirationTime",
                    },
                    {
                        label: "添加人",
                        prop: "createUserId",
                    },


                ]
            };
        },
        mounted() {
            this.getTableData()
        },
        methods: {
            reset(){
                this.searchObj={
                    platformName: "",
                    page: 1,
                    size: 10
                }
                this.getTableData()
            },
            async getTableData(pagination) {
                this.loading = true
                if (pagination) {
                    if (pagination.page) {
                        this.searchObj.page = pagination.page
                        this.searchObj.size = pagination.limit
                    }
                }
                let {
                    success,
                    message,
                    data
                } = await _FindAllPage(this.searchObj)
                if (success == true) {
                    this.tableData = data.list
                    this.total = data.total
                }
                this.loading = false
            },
        }
    }
</script>

<style>

</style>