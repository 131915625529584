
import service from '@/until/api'

// 平台管理--分页查询
export function _FindAllPage(data) {
    return service({
        url: '/system-nacos/platformManage/findAllPage',
        method: 'GET',
        params: data
    })
}